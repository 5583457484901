export default class CountDownEvent {
  constructor(target, date) {
    this.target = target;
    this.date = this.target.dataset.goal;
    this.count;
    this.init();
  }
  init() {
    this.goal = new Date(this.date);
    this.setCountDown(this);
  }
  setCountDown(t) {
    let counter = this.countDown(this.goal);
    let end = 0;
    const countDownTimer = setTimeout(function () {
      t.setCountDown(t);
    }, 1000);
    for (let item in counter) {
      let leng = counter[item].length;
      let Dom = '';
      for (let i = 0; i < leng; i++) {
        Dom += '<small>' + counter[item].substr(i, 1) + '</small>';
      }
      this.target.querySelector('.' + item).innerHTML = Dom;
      end += parseInt(counter[item]);
    }
    if (end === 0) {
      clearTimeout(countDownTimer);
    }
  }

  countDown(goal) {
    const now = new Date();
    const left = goal.getTime() - now.getTime();
    if (left > 0) {
      const sec = Math.floor(left / 1000) % 60;
      const min = Math.floor(left / 1000 / 60) % 60;
      const hours = Math.floor(left / 1000 / 60 / 60) % 24;
      const days = Math.floor(left / 1000 / 60 / 60 / 24);
      const s = sec.toString().padStart(2, '0');
      const m = min.toString().padStart(2, '0');
      const h = hours.toString().padStart(2, '0');
      const d = days.toString().padStart(2, '0');
      this.count = { Day: d, Hour: h, Min: m, Sec: s };
    } else {
      this.count = { Day: 0, Hour: 0, Min: 0, Sec: 0 };
    }
    return this.count;
  }
}
