import CountDownEvent from './countDownEvent';

export default class CountDownAdmin {
  constructor() {
    this.init();
  }
  init() {
    const CountDowns = document.querySelectorAll('.js-countTimer');
    for (let i = 0; i < CountDowns.length; i++) {
      new CountDownEvent(CountDowns[i]);
    }
  }
}
