'use strict';
import './structure/adjustVh';
import './structure/adjustViewport';
import InViewEvent from './components/inViewEvent';
import CountDownAdmin from './components/countDownAdmin';
import MenuEvent from './components/menuEvent';
import smoothScroll from 'smooth-scroll';
import Sticky from 'sticky-js';
import imagesLoaded from 'imagesloaded';

document.addEventListener('DOMContentLoaded', () => {
  new InViewEvent();
  new CountDownAdmin();
  new MenuEvent();

  if (document.querySelector('.topNews')) {
    const Nav = document.querySelector('.p_header');
    const targetElement = document.querySelector('.topNews');

    setInterval(function () {
      // 要素の位置座標を取得
      var clientRect = targetElement.getBoundingClientRect();
      var y = clientRect.top;
      if (y < Nav.clientHeight) {
        Nav.classList.remove('TOP');
      } else {
        Nav.classList.add('TOP');
      }
    }, 10);
    let newToggleBool = true;
    const news = document.querySelectorAll('.topNewsOne');
    const mores = document.querySelectorAll('.topNewsOne__more');

    if (news.length > 5) {
      const InnerMore = mores[0].querySelector('span');
      const InnerMore2 = mores[1].querySelector('span');

      for (let j = 5; j < news.length; j++) {
        news[j].classList.add('Hide');
      }

      mores[0].addEventListener('click', (e) => {
        newToggleBool = !newToggleBool;
        // for (let i = 0; i < news.length; i++) {
        //   news[i].classList.toggle('Hide');
        // }
        if (newToggleBool) {
          InnerMore.innerHTML = 'VIEW MORE';
          for (let i = 5; i < news.length; i++) {
            news[i].classList.add('Hide');
          }
        } else {
          InnerMore.innerHTML = '<span>CLOSE</span>';
          for (let i = 0; i < news.length; i++) {
            news[i].classList.remove('Hide');
          }
        }
      });
      mores[1].addEventListener('click', (e) => {
        newToggleBool = !newToggleBool;
        // for (let i = 0; i < news.length; i++) {
        //   news[i].classList.toggle('Hide');
        // }
        if (newToggleBool) {
          InnerMore2.innerHTML = 'VIEW MORE';
          for (let i = 5; i < news.length; i++) {
            news[i].classList.add('Hide');
          }
        } else {
          InnerMore2.innerHTML = '<span>CLOSE</span>';
          for (let i = 0; i < news.length; i++) {
            news[i].classList.remove('Hide');
          }
        }
      });
    } else {
      mores[0].style.display = 'none';
      mores[1].style.display = 'none';
    }
  }

  if (document.querySelector('.topStageOneHeroNav')) {
    const allDayToggle = document.querySelector('.js-allDayToggle');
    const dayAToggle = document.querySelector('.js-DayAToggle');
    const dayBToggle = document.querySelector('.js-DayBToggle');

    const allDayToggleDJ = document.querySelector('.js-allDayToggleDJ');
    const dayAToggleDJ = document.querySelector('.js-DayAToggleDJ');
    const dayBToggleDJ = document.querySelector('.js-DayBToggleDJ');

    const dayAs = document.querySelectorAll('.js-dayA');
    const dayBs = document.querySelectorAll('.js-dayB');

    const dayDJAs = document.querySelectorAll('.js-dayDJA');
    const dayDJBs = document.querySelectorAll('.js-dayDJB');

    allDayToggle.addEventListener('click', () => {
      allDayToggleEvent();
    });

    function allDayToggleEvent() {
      allDayToggle.classList.add('Act');
      dayAToggle.classList.remove('Act');
      dayBToggle.classList.remove('Act');
      for (let i = 0; i < dayAs.length; i++) {
        dayAs[i].classList.remove('DayAct');
      }
      for (let j = 0; j < dayBs.length; j++) {
        dayBs[j].classList.remove('DayAct');
      }
      setTimeout(() => {
        for (let i = 0; i < dayAs.length; i++) {
          dayAs[i].classList.remove('None');
          dayAs[i].classList.add('DayAct');
        }
        for (let j = 0; j < dayBs.length; j++) {
          dayBs[j].classList.remove('None');
          dayBs[j].classList.add('DayAct');
        }
      }, 90);
    }

    allDayToggleDJ.addEventListener('click', () => {
      allDayDJToggleEvent();
    });

    function allDayDJToggleEvent() {
      allDayToggleDJ.classList.add('Act');
      dayAToggleDJ.classList.remove('Act');
      dayBToggleDJ.classList.remove('Act');
      for (let i = 0; i < dayDJAs.length; i++) {
        dayDJAs[i].classList.remove('DayAct');
      }
      for (let j = 0; j < dayDJBs.length; j++) {
        dayDJBs[j].classList.remove('DayAct');
      }
      setTimeout(() => {
        for (let i = 0; i < dayDJAs.length; i++) {
          dayDJAs[i].classList.remove('None');
          dayDJAs[i].classList.add('DayAct');
        }
        for (let j = 0; j < dayDJBs.length; j++) {
          dayDJBs[j].classList.remove('None');
          dayDJBs[j].classList.add('DayAct');
        }
      }, 90);
    }

    dayAToggle.addEventListener('click', () => {
      dayAToggleEvent();
    });

    function dayAToggleEvent() {
      allDayToggle.classList.remove('Act');
      dayAToggle.classList.add('Act');
      dayBToggle.classList.remove('Act');
      for (let i = 0; i < dayAs.length; i++) {
        dayAs[i].classList.remove('DayAct');
      }
      for (let j = 0; j < dayBs.length; j++) {
        dayBs[j].classList.remove('DayAct');
      }
      setTimeout(() => {
        for (let i = 0; i < dayAs.length; i++) {
          dayAs[i].classList.remove('None');
          dayAs[i].classList.add('DayAct');
        }
        for (let j = 0; j < dayBs.length; j++) {
          dayBs[j].classList.add('None');
          dayBs[j].classList.remove('DayAct');
        }
      }, 90);
    }

    dayAToggleDJ.addEventListener('click', () => {
      dayADJToggleEvent();
    });
    function dayADJToggleEvent() {
      allDayToggleDJ.classList.remove('Act');
      dayAToggleDJ.classList.add('Act');
      dayBToggleDJ.classList.remove('Act');

      for (let i = 0; i < dayDJAs.length; i++) {
        dayDJAs[i].classList.remove('DayAct');
      }
      for (let j = 0; j < dayDJBs.length; j++) {
        dayDJBs[j].classList.remove('DayAct');
      }
      setTimeout(() => {
        for (let i = 0; i < dayDJAs.length; i++) {
          dayDJAs[i].classList.remove('None');
          dayDJAs[i].classList.add('DayAct');
        }
        for (let j = 0; j < dayDJBs.length; j++) {
          dayDJBs[j].classList.add('None');
          dayDJBs[j].classList.remove('DayAct');
        }
      }, 90);
    }

    dayBToggle.addEventListener('click', () => {
      dayBToggleEvent();
    });
    function dayBToggleEvent() {
      allDayToggle.classList.remove('Act');
      dayAToggle.classList.remove('Act');
      dayBToggle.classList.add('Act');

      for (let i = 0; i < dayAs.length; i++) {
        dayAs[i].classList.remove('DayAct');
      }
      for (let j = 0; j < dayBs.length; j++) {
        dayBs[j].classList.remove('DayAct');
      }

      setTimeout(() => {
        for (let i = 0; i < dayAs.length; i++) {
          dayAs[i].classList.add('None');
          dayAs[i].classList.remove('DayAct');
        }
        for (let j = 0; j < dayBs.length; j++) {
          dayBs[j].classList.remove('None');
          dayBs[j].classList.add('DayAct');
        }
      }, 90);
    }

    dayBToggleDJ.addEventListener('click', () => {
      dayBDJToggleEvent();
    });
    function dayBDJToggleEvent() {
      allDayToggleDJ.classList.remove('Act');
      dayAToggleDJ.classList.remove('Act');
      dayBToggleDJ.classList.add('Act');

      for (let i = 0; i < dayDJAs.length; i++) {
        dayDJAs[i].classList.remove('DayAct');
      }
      for (let j = 0; j < dayDJBs.length; j++) {
        dayDJBs[j].classList.remove('DayAct');
      }

      setTimeout(() => {
        for (let i = 0; i < dayDJAs.length; i++) {
          dayDJAs[i].classList.add('None');
          dayDJAs[i].classList.remove('DayAct');
        }
        for (let j = 0; j < dayDJBs.length; j++) {
          dayDJBs[j].classList.remove('None');
          dayDJBs[j].classList.add('DayAct');
        }
      }, 90);
    }
  }

  if (document.querySelectorAll('.sticky')) {
    new Sticky('.sticky', {});
  }

  const ImagesBottom = document.querySelector('.js-slideImagesBottomInner');
  const ImagesElement = document.querySelector('.js-slideImagesBottom');

  setInterval(function () {
    // 要素の位置座標を取得
    var clientRect = ImagesElement.getBoundingClientRect();
    var y = clientRect.top;
    if (y < 0) {
      ImagesBottom.classList.add('Fit');
    } else {
      ImagesBottom.classList.remove('Fit');
    }
  }, 10);

  let prev = 0;
  let next = 1;

  if (document.querySelectorAll('.js-slideImagesOne').length > 0) {
    const sliders = document.querySelectorAll('.js-slideImagesOne');
    setInterval(function () {
      sliders[prev].classList.remove('Show');
      sliders[next].classList.add('Show');
      prev += 1;
      next += 1;
      if (next >= sliders.length) {
        next = 0;
      }
      if (prev >= sliders.length) {
        prev = 0;
      }
    }, 10000);
    sliders[0].classList.add('Show');
  }

  let prevBt = 0;
  let nextBt = 1;

  const slidersBt = document.querySelectorAll('.js-slideImagesOneBt');
  setInterval(function () {
    slidersBt[prevBt].classList.remove('Show');
    slidersBt[nextBt].classList.add('Show');
    prevBt += 1;
    nextBt += 1;
    if (nextBt >= slidersBt.length) {
      nextBt = 0;
    }
    if (prevBt >= slidersBt.length) {
      prevBt = 0;
    }
  }, 10000);
  slidersBt[0].classList.add('Show');

  let smoothScrollObj = document.getElementsByClassName('js-smoothScroll');
  let option = {
    speed: 10,
    durationMax: 1000,
    durationMin: 800,
    clip: true,
    offset: 0,
    easing: 'easeInOutQuint',
    updateURL: false,
    popstate: false,
  };
  for (let i = 0; i < smoothScrollObj.length; i++) {
    smoothScrollObj[i].addEventListener('click', function (e) {
      let scroll = new smoothScroll('a[href*="#"]', option);
      menuWrap.classList.remove('Act');
      menuToggle.classList.remove('Act');
      enableBodyScroll(menuWrapInner);
    });
  }

  const elem = document.querySelector('.topKV');
  const Loader = document.querySelector('.topLoaderWrap');

  imagesLoaded(elem, function () {
    setTimeout(() => {
      Loader.classList.add('Hide');
    }, 1000);
    setTimeout(() => {
      Loader.classList.add('None');
    }, 4000);
  });
});
