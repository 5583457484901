const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

export default class MenuEvent {
  constructor() {
    this.init();
  }
  init() {
    const menuBox = document.querySelector('.p_headerSPMenu');
    const menuToggle = document.querySelector('.p_headerMenu');
    const menuClose = document.querySelector('.p_headerSPMenuHead');
    const modalInner = document.querySelector('.p_headerMenu');
    const headerNavItems = document.querySelectorAll('.p_headerSPMenuNavItem');

    if (!document.querySelector('.p_headerMenu')) {
      return false;
    }
    function menuEv() {
      if (menuBox.classList.contains('Act') == true) {
        // enableBodyScroll(modalInner);
      } else {
        // zdisableBodyScroll(modalInner);
      }
      menuBox.classList.toggle('Act');
    }

    menuToggle.addEventListener('click', () => {
      menuEv();
    });

    menuClose.addEventListener('click', () => {
      menuEv();
    });

    for (let i = 0; i < headerNavItems.length; i++) {
      headerNavItems[i].addEventListener('click', () => {
        menuEv();
      });
    }

    menuToggle.addEventListener('mouseover', () => {
      // Rect.innerHTML = '<span>MENU</span>';
    });
    menuToggle.addEventListener('mouseout', () => {
      // Rect.innerHTML = '';
    });
  }
}
